const bodyWrapper = document.querySelector('.bodyWrapper');
const header = document.querySelector('header');
const burgers = document.querySelectorAll('.burger');
let lastScrollPosition = 0;

// HEADER SPACING
document.addEventListener('DOMContentLoaded', () => {
    let headerHeight = header.offsetHeight;

    bodyWrapper.style.paddingTop = headerHeight + 'px';

    window.addEventListener('resize', () => {
        headerHeight = header.offsetHeight;
        bodyWrapper.style.paddingTop = headerHeight + 'px';
    });
});

// BURGER TRIGGER
burgers.forEach((burger) => {
    burger.addEventListener('click', () => {
        document.body.classList.toggle('menu-shown');
    });
});

// HIDE HEADER ON SCROLL
function checkScrollPosition() {
    let scrollY = bodyWrapper.scrollTop;
    let headerHeight = header.offsetHeight;

    header.classList.toggle('hide-nav-fully', scrollY > headerHeight);
    header.classList.toggle('hide-nav-partially', scrollY < lastScrollPosition && scrollY > headerHeight);

    lastScrollPosition = scrollY;
}

bodyWrapper.addEventListener('scroll', checkScrollPosition);
window.addEventListener('resize', checkScrollPosition);

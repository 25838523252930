document.addEventListener("DOMContentLoaded", function() {
    function wrapTextInSpan(element, searchText) {
        var regex = new RegExp(searchText, "gi");
        var html = element.innerHTML;
        html = html.replace(regex, '<span class="unbreakable">' + searchText + '</span>');
        element.innerHTML = html;
    }

    var elementsToWrap = document.querySelectorAll("p, span, li, a, h1, h2, h3, h4, h5, h6");
    elementsToWrap.forEach(function(element) {
        wrapTextInSpan(element, "ZAC Pickleball");
    });
});

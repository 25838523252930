document.addEventListener('DOMContentLoaded', () => {
    const buttons = document.querySelectorAll('.btn');

    function moveBubble(event) {
        const button = event.target;
        const bubble = button.querySelector('.bubble');
        const x = event.clientX - button.getBoundingClientRect().left;
        const y = event.clientY - button.getBoundingClientRect().top;

        bubble.style.left = x + 'px';
        bubble.style.top = y + 'px';
    }

    buttons.forEach((button) => {
        button.addEventListener('mouseenter', () => {
            const bubble = button.querySelector('.bubble');

            bubble.classList.add('show-bubble');
            button.addEventListener('mousemove', moveBubble);
        });

        button.addEventListener('mouseleave', () => {
            const bubble = button.querySelector('.bubble');

            bubble.classList.remove('show-bubble');
            button.removeEventListener('mousemove', moveBubble);
        });
    });
});

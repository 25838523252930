const bodyWrapper = document.querySelector('.bodyWrapper');

document.addEventListener('DOMContentLoaded', () => {
    const submitButtons = document.querySelectorAll('input[type="submit"]');

    submitButtons.forEach((button) => {
        button.addEventListener('click', () => {
            setTimeout(() => {
                const formWrapper = document.querySelector('.form-wrapper').offsetTop;

                bodyWrapper.scrollTo({
                    top: formWrapper,
                    behavior: 'smooth',
                });
            }, 500);
        });
    });

    subscribeMailchimp();
});

//Mailchimp
function subscribeMailchimp() {
    var subscribeForm = document.querySelectorAll('.subscribe-form');
    if (subscribeForm.length < 1) {
        return true;
    }

    subscribeForm.forEach((el) => {
        let elResult = el.querySelector('.subscribe-form-result');

        el.querySelector('form').addEventListener('submit', (event) => {
            let formData = new FormData(event.target);

            event.preventDefault();
            elResult.style.display = 'none';

            fetch(event.target.action, {
                method: 'POST',
                body: formData,
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    elResult.innerHTML = data.message;
                    elResult.style.display = 'block';

                    if (data.alert !== 'error') {
                        event.target.reset();

                        setTimeout(() => {
                            elResult.style.display = 'none';
                        }, 5000);
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        });
    });
}

document.addEventListener('DOMContentLoaded', () => {
    const burgers = document.querySelectorAll('.burger');
    const subMenuTrigger = document.querySelectorAll(
        '.nav-primary .menu-item-has-children > a'
    );
    const mobileSubMenuTrigger = document.querySelectorAll(
        '.nav-mobile .menu-item-has-children > a'
    );

    // DESKTOP MENU SUBMENU
    subMenuTrigger.forEach((trigger) => {
        trigger.addEventListener('click', (e) => {
            e.preventDefault();
        });
    });

    // MOBILE MENU SUBMENU
    mobileSubMenuTrigger.forEach((trigger) => {
        trigger.addEventListener('click', (e) => {
            e.preventDefault();

            const subMenu = trigger.nextElementSibling;
            const isSubMenuOpen = subMenu.classList.contains('active');

            trigger.classList.toggle('active');
            subMenu.classList.toggle('active');

            subMenu.style.maxHeight = isSubMenuOpen ? '0' : subMenu.scrollHeight + 'px';
        });
    });

    // BURGER TRIGGER CLOSES ALL SUBS
    burgers.forEach((burger) => {
        burger.addEventListener('click', () => {
            mobileSubMenuTrigger.forEach((trigger) => {
                trigger.classList.remove('active');
                const subMenu = trigger.nextElementSibling;

                subMenu.classList.remove('active');
                subMenu.style.maxHeight = '0';
            });
        });
    });
});
